<template>
  <v-row>
    <v-col v-for="party in firstParty" :key="party.id" cols="12" md="6" xl="3">
      <PartyIndexCard :party="party" />
    </v-col>
    <v-col
      v-for="party in forestManagers"
      :key="party.id"
      cols="12"
      md="6"
      xl="3"
    >
      <PartyIndexForestCard :party="party" />
    </v-col>
  </v-row>
</template>

<script>
import PartyIndexCard from './PartyIndexCard'
import PartyIndexForestCard from './PartyIndexForestCard'
export default {
  components: {
    PartyIndexCard,
    PartyIndexForestCard
  },
  props: {
    orderId: {
      type: String,
      required: true
    },
    forestManagerIds: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      parties: [],
      forestManagers: []
    }
  },
  computed: {
    firstParty() {
      return this.parties.slice(0, 1)
    }
  },
  async created() {
    this.parties = await this.filterParties()
    this.forestManagers = await this.filterForestManagers()
  },
  methods: {
    async filterParties() {
      const orderIds = this.orderId
      return await this.$store.dispatch('party/filter', { orderIds })
    },
    async filterForestManagers() {
      let array = this.forestManagerIds
      let forestManagers = []
      for (let i = 0; i < array.length; i++) {
        const response = await this.$store.dispatch('userGroup/fetch', array[i])
        forestManagers.push(response)
      }
      return forestManagers
    }
  }
}
</script>

<style lang="scss" scoped></style>
