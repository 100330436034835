<template>
  <v-row v-if="loads.length <= 2" no-gutters
    ><v-col v-for="(load, i) in loads" :key="i" cols="12" md="6">
      <BaseAddressCard
        color="white"
        :name="i === 0 ? $t('load.originAddress') : $t('load.deliveryTo')"
        :address="load.attributes.location"/></v-col
  ></v-row>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters('load', ['loads'])
  }
}
</script>
