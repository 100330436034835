<template>
  <v-card outlined>
    <v-list>
      <v-list-item>
        <v-list-item-avatar>
          <avatar :username="party.attributes.companyName"></avatar
        ></v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>{{
            party.attributes.companyName | upperCase
          }}</v-list-item-title>
          <v-list-item-subtitle>
            {{ $tc(`user.${role}`, 1) | titleCase }}</v-list-item-subtitle
          >
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script>
import Avatar from 'vue-avatar'

export default {
  components: {
    Avatar
  },
  props: {
    party: {
      type: Object,
      required: true
    }
  },
  computed: {
    role() {
      const { role } = this.party.attributes.customAttributes
      return role === undefined ? 'customer' : role
    }
  }
}
</script>

<style lang="scss" scoped></style>
