<template>
  <v-card outlined>
    <v-list>
      <v-list-item>
        <v-list-item-avatar>
          <avatar :username="companyName"></avatar
        ></v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>{{ companyName | upperCase }}</v-list-item-title>
          <v-list-item-subtitle>{{
            $tc(`user.${name}`, 1) | titleCase
          }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script>
import Avatar from 'vue-avatar'

export default {
  components: {
    Avatar
  },
  props: {
    party: {
      type: Object,
      required: true
    }
  },
  computed: {
    name() {
      return this.party.attributes.category
    },
    companyName() {
      return this.party.attributes.name
    }
  }
}
</script>

<style lang="scss" scoped></style>
